.completed-header {
    min-width: 100px;
    width: 100%
}

.completed-quest {
    font-family: 'Cardo', serif;
}

.completed-quests-display {
    background-image: url('../../assets/completed-banner.png');
    height: 700px;
}

.completed-quests-container {
    height: 60vh;
    margin-top: 0px;
    min-width: 300px;
    overflow-x: scroll;
    width: 30vw;
}

@media screen and (max-width: 414px)and (max-height) {
    .completed-quests-container {
        height: 25vh;
    }

    .completed-quests-display {
        height: 25vh;
        margin-top: 50px;
        width: 20vw;
    }
}
