.back-arrow {
    margin: 10px;
    width: 50px;
}

.new-quest {
    background-image: url('../../assets/quest-banner.png');
}

.quest-text {
    font-family: 'VT323', monospace;
    text-align: center;
}

.quest-button {
    margin-top: 10px;
}

.quest-header {
    font-family: 'MedievalSharp', cursive;
    text-align: center;
}

.quest-name-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 45%;
}

.scroll {
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    min-height: 450px;
    min-width: 500px;
    width: 85vw;
}

@media screen and (max-width: 414px) and (max-height) {
    .scroll{
        height: 60vh;
        margin-top: 0px;
        width: 100px;
    }
}
