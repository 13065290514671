button {
  background-color: lightgrey;
  cursor: pointer;
  font-family: 'VT323', monospace;
  font-size: 25px;
  margin: 0px 3px;
  margin-bottom: 15px;
}

.character {
  width: 25em;
}

header {
  display: flex;
  justify-content: space-between;
  width: 100vw;
}

main {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}

nav {
  background-image: url('./assets/bubble.png');
  background-size: 100%;
  background-repeat: no-repeat;
  font-family: 'VT323', monospace;
  font-size: 2em;
  height: 175px;
  text-align: center;
  width: 16em; 
}

.setting-icon {
  margin-right: 20px;
  margin-top: 15px;
  width: 30px;
}

.title {
  align-self: flex-start;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 20em;
}

.theme-button {
  font-size: 20px;
}

.welcome-message {
  font-size: 30px;
  margin-bottom: 5px;
  margin-top: 20px;
}

/* Backgrounds */
.castle-background {
  background-image: url('./assets/castle.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.forest-background {
  background-image: url('./assets/forest.png');
}

.meadow-background {
  background-image: url('./assets/meadow.png');
  background-size: cover;
}

@media screen and (max-width: 414px) and (max-height){
  .character {
    width: 5em;
  }

  button {
    font-size: 20px;
    margin: 6px 3px;
  }

  nav {
    /* height: 80px; */
    overflow-x: scroll;
    width: 50vw;
  }

  .title {
    width: 10em;
  }

  .welcome-message {
    margin-top: 10px;
  }
}
